import axios from "@/plugins/axios";

const routes = [
  {
    path: "/",
    redirect: { name: "servicedashboard" },
  },
  {
    path: "/auth/:company",
    name: "Register",
    beforeEnter(to, from, next) {
      if (!to.params.company) {
        next({ name: "404" });
      }
    },
  },
  {
    path: "/trial-expired",
    name: "trialexpired",
    component: () => import("@/templates/TrialLimitExceed.vue"),
  },
  {
    path: "/multime",
    name: "multime",
    component: () => import("@/templates/MultiMeContainer.vue"),
  },
  {
    path: "/multime/exercise",
    name: "multimeExercise",
    component: () => import("@/templates/MultiMeExerciseContainer.vue"),
  },
  {
    path: "/multime/result/:id",
    name: "multimeResult",
    component: () => import("@/templates/MultiMeResultContainer.vue"),
  },
  {
    path: "/stream",
    component: () => import("@/templates/Streams.vue"),
    children: [
      {
        path: ":id",
        name: "stream",
        component: () => import("@/templates/StreamsContainer.vue"),
      },
      {
        path: ":id/certificate",
        name: "stream-certificate",
        component: () => import("@/templates/StreamCertificate.vue"),
      },
      {
        path: ":id/detail",
        name: "stream-detail",
        component: () => import("@/organisms/StreamDetail.vue"),
      },
      {
        path: ":id/:localisation/ale/:aleId/miu/:elementId",
        name: "stream-miu",
        component: () => import("@/templates/StreamMiuContainer.vue"),
        meta: {
          hideHeader: true,
          hideFooter: true,
        },
      },
      {
        path: ":id/:localisation/ale/:aleId/miu/:elementId/:customBack",
        name: "stream-miu-custom-back",
        component: () => import("@/templates/StreamMiuContainer.vue"),
        meta: {
          hideHeader: true,
          hideFooter: true,
        },
      },
      {
        path: ":id/:localisation/ale/:aleId/miu/:elementId/completed/:state",
        name: "stream-miu-completed",
        component: () => import("@/organisms/stream-transition-pages/EndOfMiu.vue"),
        meta: {
          hideHeader: true,
          hideFooter: true,
        },
      },
      {
        path: ":id/:localisation/ale/:aleId/mission/:elementId/:status",
        name: "stream-mission",
        component: () => import("@/templates/StreamMissionContainer.vue"),
        meta: {
          hideHeader: true,
          hideFooter: true,
        },
      },
    ],
  },
  {
    path: "/multime/redeem/:token",
    beforeEnter(to, from, next) {
      const { token } = to.params;
      axios
        .get(`/api/rest/multime/redeem/${token}`)
        .then((res) => {
          next(`/multime/result/${res.data.id}`);
        })
        .catch(() => {
          next();
        });
    },
  },
  {
    path: "/stepper/:stepperId",
    name: "stepperStory",
    component: () => import("@/organisms/StepperStory.vue"),
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/dashboard",
    name: "servicedashboard",
    component: () => import("@/templates/HomePage.vue"),
  },
  {
    path: "/service-list",
    name: "serviceListPage",
    component: () => import("@/templates/ServiceListPage.vue"),
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/profiling",
    alias: "/profiling/:wizardId",
    name: "profiling-wizard",
    component: () => import("@/templates/ProfilingContainer.vue"),
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/programs/:id",
    name: "program",
    component: () => import("@/templates/MasterDashboard.vue"),
  },
  {
    path: "/journal",
    name: "journal",
    component: () => import("@/templates/JournalContainer.vue"),
    children: [
      {
        path: "",
        name: "journal-softskills",
        component: () => import("@/organisms/journal/JournalSoftSkills.vue"),
      },
      {
        path: "reflections",
        name: "journal-reflections",
        component: () => import("@/organisms/journal/JournalReflections.vue"),
      },
    ],
  },
  {
    path: "/community",
    component: () => import("@/templates/CommunityContainer.vue"),
    children: [
      {
        path: "",
        name: "community-posts",
        component: () => import("@/organisms/community/CommunityPosts.vue"),
      },
      {
        path: "/users-list",
        name: "community-users",
        component: () => import("@/organisms/community/CommunityUsers.vue"),
      },
    ],
  },
  {
    path: "/assessments",
    name: "assessments",
    component: () => import("@/templates/AssessmentsContainer.vue"),
  },
  {
    path: "/service-unavailable",
    name: "serviceunavailable",
    component: () => import("@/templates/ServiceUnavailable.vue"),
  },
  {
    path: "/invite-colleagues",
    name: "inviteColleagues",
    component: () => import("@/templates/InviteComponent.vue"),
  },
  {
    path: "/webinar",
    component: () => import("@/templates/Webinar.vue"),
    children: [
      {
        path: "",
        name: "webinar",
        component: () => import("@/templates/WebinarDashboard.vue"),
      },
      {
        path: ":id/detail",
        name: "webinarDetail",
        component: () => import("@/organisms/webinars/WebinarDetail.vue"),
      },
      {
        path: ":id/programs/:programId",
        component: () => import("@/templates/AugmentedMaster.vue"),
        props: true,
        beforeEnter(to, from, next) {
          axios
            .get(`/api/rest/webinar/${to.params.id}/access`)
            .then((res) => {
              // if (from.path === "/") {
              //   this.$mixpanel.track("AugmentedWebinarCTA", {
              //     webinarId: to.params.id,
              //   });
              // }
              if (res.data.exists) next();
              else next({ name: "webinar" });
            })
            .catch(() => {
              next("/dashboard");
            });
        },
      },
      {
        path: "session/:sessionId",
        name: "webinarSession",
        component: () => import("@/organisms/webinars/WebinarRegistration.vue"),
      },
    ],
  },

  {
    path: "/programs/:programId/modules/:moduleId/missions/:id/scheduled",
    name: "missionScheduled",
    component: () => import("@/organisms/transition-pages/MissionScheduled.vue"),
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/programs/:programId/modules/:moduleId/missions/:missionId/complete",
    name: "missionWelcomeBack",
    component: () => import("@/organisms/transition-pages/MissionWelcomeBack.vue"),
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/programs/:programId/modules/:moduleId/missions/:missionId/completed",
    name: "missionCompleted",
    component: () => import("@/organisms/transition-pages/MissionCompleted.vue"),
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("@/templates/NotificationsContainer.vue"),
  },
  {
    path: "/programs/:programId/modules/:id",
    name: "moduleCover",
    component: () => import("@/organisms/transition-pages/ModuleCover.vue"),
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/programs/:programId/modules/:moduleId/completed",
    name: "module-evaluation",
    component: () => import("@/organisms/transition-pages/ModuleEvaluation.vue"),
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/programs/:programId/modules/:moduleId/sharing",
    name: "moduleSharing",
    component: () => import("@/organisms/transition-pages/ModuleSharing.vue"),
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/programs/:programId/modules/:moduleId/finale",
    name: "moduleFinale",
    component: () => import("@/organisms/transition-pages/ModuleFinale.vue"),
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/programs/:programId/finale",
    name: "masterFinale",
    component: () => import("@/organisms/transition-pages/MasterFinale.vue"),
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/programs/:id/certificate",
    name: "masterCertificate",
    component: () => import("@/templates/MasterCertificate.vue"),
  },
  {
    path: "/privacy-legend",
    name: "privacy-legend",
    component: () => import("@/templates/PrivacyLegend.vue"),
  },
  {
    path: "/user-profile/:id",
    name: "ProfilePublicContainer",
    component: () => import("@/templates/profile/ProfilePublicContainer.vue"),
  },
  {
    path: "/profile",
    name: "profilecontainer",
    component: () => import("@/templates/profile/ProfileContainer.vue"),
    children: [
      {
        path: "",
        name: "userProfile",
        components: {
          profile: () => import("@/templates/profile/UserProfile.vue"),
        },
      },
      {
        path: "demographic",
        component: () => import("@/templates/profile/ProfileDemographic.vue"),
      },
      {
        path: "parenthood",
        component: () => import("@/templates/profile/ProfileParenthood.vue"),
      },
      {
        path: "caregiving",
        component: () => import("@/templates/profile/ProfileCaregiving.vue"),
      },
      {
        path: "reminder",
        component: () => import("@/templates/profile/ProfileReminder.vue"),
      },
      {
        path: "diary",
        component: () => import("@/templates/ProfileDiary.vue"),
      },
      {
        path: "workplace",
        component: () => import("@/templates/profile/ProfileWorkplace.vue"),
      },
      {
        path: "transitions",
        name: "profile-transitions",
        component: () => import("@/templates/profile/LifeWorkEvents.vue"),
      },
    ],
  },
  {
    path: "/company/board",
    name: "company-bulletin",
    component: () => import("@/templates/CompanyBulletinBoard.vue"),
  },
  {
    path: "/programs/:programId/modules/:moduleId/pages/:id",
    name: "page",
    component: () => import("@/templates/MasterPageContainer.vue"),
  },
  {
    path: "/invite/redeem/:token",
    beforeEnter(to, from, next) {
      const { token } = to.params;
      axios
        .get(`/api/rest/invite/redeem/${token}`)
        .then((res) => {
          next(`${res.data.link}?inviteToken=${token}`);
        })
        .catch(() => {
          next();
        });
    },
  },
  {
    path: "/something-went-wrong",
    name: "exceptionpage",
    component: () => import("@/templates/500Page.vue"),
  },
  {
    path: "/:company",
    name: "OldRegister",
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/templates/404page.vue"),
  },
];

export default routes;
