<template>
  <div>
    <b-navbar-nav>
      <template v-for="(menuItem, i) in menu">
        <b-nav-item
          v-if="menuItem.type === 'notifications'"
          role="menuitem"
          :key="i"
          class="d-flex align-items-center notification"
          link-classes="py-0"
          @click="changeRoute('notifications', menuItem.idMenu, menuItem.route)"
        >
          <b-icon-bell font-scale="1.5" :aria-label="$t('global.menu.notifications')" />
          <b-badge v-if="notificationsCount > 0" pill variant="secondary">
            {{ notificationsCount }}
          </b-badge>
        </b-nav-item>

        <div
          v-else-if="menuItem.type === 'profile'"
          role="menuitem"
          :aria-label="$t('global.menu.profile')"
          class="d-flex mr-1 mr-lg-3 profile align-items-center"
          :key="`profile-${i}`"
        >
          <profile-drop-down
            :aria-label="$t('global.menu.profile')"
            :menu-item="menuItem.children"
            :current-path="currentPath"
            :analytics="menu.analytics ? menu.analytics : null"
            :company-logo="menuItem.logo"
            @ctaClicked="
              handleServiceAccess(
                $event.label,
                $event.id,
                menu.analytics.id,
                'utility',
                'company-dashboard'
              )
            "
            @changeRoute="changeRoute($event.label, $event.id, $event.route)"
            @changePage="changePage($event.label, $event.id, $event.route)"
            @logout="$emit('logout')"
            @trackDropdown="trackMenu(i, menuItem.idMenu)"
          >
          </profile-drop-down>
        </div>

        <b-nav-item
          v-else-if="menuItem.children && menuItem.children.length === 0 && !isMobile"
          role="menuitem"
          class="d-flex align-items-center nav-link"
          link-classes="h6 py-0 my-0"
          :class="currentPath.includes(menuItem.route) ? 'active' : ''"
          @click="changeRoute(menuItem.label, menuItem.idMenu, menuItem.route)"
          :key="`nav-item-${i}`"
        >
          {{ menuItem.label }}
        </b-nav-item>

        <b-nav-item-dropdown
          v-else-if="menuItem.type === 'master' && !isMobile"
          role="list"
          :text="menuItem.label"
          class="d-flex align-items-center h6 py-0 my-0"
          :class="currentPath.includes('master') || currentPath.includes('stream') ? 'active' : ''"
          :key="`programs-${i}`"
          left
          @shown="trackMenu(menuItem.label, menuItem.idMenu)"
        >
          <div :key="i" v-for="(menuChildren, i) in menuItem.children" role="listitem">
            <b-dropdown-item
              class="d-flex align-items-center py-1"
              @click="
                handleServiceAccess(
                  menuChildren.label,
                  menuChildren.idMenu,
                  menuChildren.id,
                  getRouteName(menuChildren.route),
                  getRouteName(menuChildren.route)
                )
              "
              :class="serviceActive(menuChildren.code) ? 'active' : ''"
              role="listitem"
            >
              {{ menuChildren.label }}
            </b-dropdown-item>
            <b-dropdown-divider v-if="i < menuItem.children.length - 1" class="px-4" />
          </div>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown
          v-else-if="menuItem.children && menuItem.children.length > 0 && !isMobile"
          :text="menuItem.label"
          class="d-flex align-items-center h6 py-0 my-0"
          :class="currentPath === menuItem.route ? 'active' : ''"
          :key="`dropdown-${i}`"
          left
          @shown="trackMenu(menuItem.label, menuItem.idMenu)"
          role="list"
        >
          <b-dropdown-item
            v-for="(menuChildren, i) in menuItem.children"
            :key="i"
            role="listitem"
            class="d-flex align-items-center"
            :class="currentPath === menuChildren.route ? 'active' : ''"
            @click="changeRoute(menuChildren.label, menuChildren.idMenu, menuChildren.route)"
          >
            {{ menuChildren.label }}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item
          v-else-if="menuItem.type !== 'analytics' && menuItem.type !== 'logo' && !isMobile"
          role="menuitem"
          class="d-flex align-items-center nav-link"
          link-classes="h6 py-0 my-0"
          :class="currentPath === menuItem.route ? 'active' : ''"
          :key="`analytics-${i}`"
          @click="changeRoute(menuItem.label, menuItem.idMenu, menuItem.route)"
        >
          {{ menuItem.label }}
        </b-nav-item>
      </template>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import roleMixin from "../mixins/roleMixin";
import ProfileDropDown from "./ProfileDropDown.vue";

export default {
  name: "MenuLinks",
  components: { ProfileDropDown },
  props: {
    isMobile: Boolean,
  },
  methods: {
    serviceActive(code) {
      if (this.currentPath.includes("stream")) {
        return this.$route.params.id === code;
      }
      return this.servicePath === code;
    },
    changeRoute(labelMenu, idMenu, route) {
      this.trackMenu(labelMenu, idMenu);
      this.$router.push(route);
    },
    changePage(labelMenu, idMenu, route) {
      this.trackMenu(labelMenu, idMenu);
      window.location.replace(route);
    },
    // eslint-disable-next-line default-param-last
    handleServiceAccess(labelMenu, idMenu, id, type = "", routeName) {
      this.trackMenu(labelMenu, idMenu);
      this.accessService(id, type, routeName);
    },
    getRouteName(route) {
      return route.includes("stream") ? "stream" : "program";
    },
    // trackMenu(labelMenu, idMenu) {
    //   this.$mixpanel.track("clickMenu", {
    //     labelMenu,
    //     idMenu,
    //   });
    // },
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    servicePath() {
      return this.$route.fullPath.split("?")[1];
    },
    ...mapGetters({
      notificationsCount: "user/getNotificationsCount",
      menu: "user/getMenu",
    }),
  },
  mixins: [roleMixin],
};
</script>

<style scoped lang="scss">
.btn {
  &:focus {
    background-color: $color-white !important;
    color: $color-orange !important;
  }
}

::v-deep .b-avatar-img img {
  width: auto;
  height: auto;
  max-width: 50px;
}

.notification {
  position: relative;
  .badge {
    line-height: 12px;
    font-size: 12px;
    padding: 3px 5px 1px;
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
</style>
